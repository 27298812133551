.dialog-overlay {
  z-index: 20;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.dialog-body {
  background-color: white;
  position: absolute;
  width: calc(100% - 40px);
  max-height: 90%;
  height: 725px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  outline: none;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.dialog-body.overflow-inherit {
  overflow: inherit;
}

.dialog-body.dialog-padding {
  padding: 0 2rem 2rem 2rem;
}

.dialog-body.is-full {
  height: 80%;
  width: 100%;
}

.dialog-body.is-small {
  max-width: 676px;
  height: min-content;
}

.dialog-body.is-xsmall {
  max-width: 400px;
  height: min-content;
}

.dialog-body.is-large {
  max-width: 1031px;
}

.dialog-body.is-wide {
  max-width: 900px;
}

.dialog-body.is-slide {
  max-width: 1200px;
  height: min-content;
}

.dialog-header {
  display: flex;
  z-index: 1;
  background: #fff;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 2rem;
}

.dialog-title {
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
}

.dialog-open {
  overflow: hidden;
}

.dialog-body form {
  height: 550px;
}

.dialog-footer {
  z-index: 1;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
}

.dialog-label {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 20px;
}

.dialog-cancel {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: black;
  transition: all 100ms;
}

.dialog-cancel:hover {
  color: gray;
}

.dialog-body .close-button {
  color: black;
  transition: all 100ms;
  cursor: pointer;
  position: absolute;
  right: -20px;
  width: 23px;
  height: 23px;
  font-size: 22px;
}

.dialog-body .close-button:hover {
  color: gray;
}

@media screen and (max-width: 500px) {
  .dialog-body {
    /* max-width: 80% !important; */
    height: 100vh;
  }
}

.home .event-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;
    text-decoration: none;
    color: black;
}

.home .event-wrapper .event {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 20px;
    text-decoration: none;
    color: black;
}

.home .event-wrapper .event .image {
    object-fit: contain;
    margin-bottom: 10px;
    display: block;
    width: 200px;
    height: 200px;
}
.event .banner {
    width: 100%;
    object-fit: contain;
    margin-bottom: 20px;
}

.event.pink .title span,
.event.pink h1 {
    background-color: #ffeeff;
    width: fit-content;
}

.event.pink h2,
.event.pink h3,
.event.pink h3 {
    color: #ffeeff;
    width: fit-content;
}

.event.yellow .title span,
.event.yellow h1 {
    background-color:  #ff613b;
    width: fit-content;
}

.event.yellow h2,
.event.yellow h3,
.event.yellow h3 {
    color:  #ff613b;
    width: fit-content;
}

.event.green .title span,
.event.green h1 {
    background-color: #9edeab;
    width: fit-content;
}
.event.green h2,
.event.green h3,
.event.green h3 {
    color: #9edeab;
    width: fit-content;
}

.event.blue .title span,
.event.blue h1 {
    background-color: #b4d5ff;
    width: fit-content;
}
.event.blue h2,
.event.blue h3,
.event.blue h3 {
    color: #b4d5ff;
    width: fit-content;
}

.event.cta {
    padding: 20px 70px;
    font-size: calc((2.2 - 1) * 1.2vh + 1rem);
    text-align: center;
    color: black;
}

.event.cta a {
    color: black;
}

.event.yellow.cta {
    background-color:  #ff613b;
}

.event.pink.cta {
    background-color:  #ffeeff;
}

.event.green.cta {
    background-color:  #9edeab;
}

.event.blue.cta {
    background-color:  #b4d5ff;
}

.event .no-bg {
    background-color: transparent !important;
}
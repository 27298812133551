.header {
    width: 100%;
    box-sizing: border-box;
    padding: 1.8vw 4vw;
    pointer-events: auto;
    display: flex;
}

.header .logo {
    width: auto;
    max-width: 100%;
    max-height: 76px;
}

.header .user {
    font-size: 22px;
}

.header a {
    color: black;
    text-decoration: none;
}

.header .link {
    font-size: calc((1 - 1) * 1.2vw + 1rem);
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0em;
    text-transform: none;
    line-height: 1.8em;
    margin-right: 1vw;
    cursor: pointer;
}

.header .link.active {
    background-image: linear-gradient(currentColor,currentColor);
    background-repeat: repeat-x;
    background-size: 1px 1px;
    background-position: 0 100%;
    background-position: 0 calc(100% - 0.1em);
}
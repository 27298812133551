body {
  margin: 0;
  font-family: 'Helvetica Neue', arial, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ml-auto {
  margin-left: auto
}

.mr-auto {
  margin-right: auto;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: end;
}

.w-fit {
  width: fit-content;
}

.container {
  padding-top: 3.3vmax;
  padding-bottom: 3.3vmax;
  padding-right: 4vw;
  padding-left: 4vw;
  margin: 0 auto;
  box-sizing: content-box;
}

.title {
  margin: 2rem 0;
  line-height: calc(1.4 * (1 + (1 - 2.8)/25));
  font-weight: 600;
  font-size: calc((2.8 - 1) * 1.2vw + 1rem);
  text-align: center;
}

.title .small {
  font-size: calc((2.8 - 1) * 1.2vw + 0.5rem);
}

.subtitle {
  text-align: center;
}

.flex {
  display: flex;
}

.indent {
  margin-left: 30px;
}

.flex-column {
  flex-direction: column;
}

input[type="email"],
input[type="text"],
input[type="number"],
input[type="password"],
input[type="file"],
textarea,
select {
  opacity: .7;
  transition: opacity .2s ease-out;
  transition: background-image .2s ease-out;
  padding: 12px 12px 12px 12px;
  background: no-repeat 15px 50%;
  width: 100%;
  min-height: 20px;
  display: block;
  box-sizing: border-box;
  max-width: 250px;
  border: 1px solid #aaa;
  margin-bottom: 20px;
}

.button {
  line-height: normal;
  border-style: solid;
  border-width: 0px;
  padding: 0.9em 1.503em;
  font-style: normal;
  font-weight: 500;
  letter-spacing: .02em;
  text-transform: none;
  font-size: .9rem;
  color: #fff;
  background: #000;
  border-color: #fff;
  cursor: pointer;
  width: fit-content;
}

.visitors {
  font-size: 25px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
  padding-top: 6px;
}

.button.pink {
  background-color:  #ffeeff;
}

.button.green {
  background-color:  #9edeab;
}

.button:hover {
  opacity: .8;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.error {
  color: red
}

.table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px;
}

.table .delete {
  opacity: 0;
}

.table .entry {
  position: relative;
}

.table .entry .delete {
  opacity: 0;
  cursor: pointer;
  color: #9fb3c8;
  position: absolute;
  right: 15px;
}

.table .entry .delete:hover {
  color: red;
}

.table .entry:hover .delete {
  opacity: 1;
}

.table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table th {
  border-bottom: 1px solid #ddd;
  padding: 8px;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tr:hover {
  background-color: #ddd;
}

.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: black;
  color: white;
}

tr.expired {
  color: lightgray;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.w-45 {
  width: 45%;
}

.justify-between {
  justify-content: space-between;
}

label {
  display: block;
  margin-bottom: 10px;
}

.pointer {
  cursor: pointer;
}

.overflow-auto {
  overflow: auto;
}

.edit-img {
  max-width: 100%;
  object-fit: contain;
}